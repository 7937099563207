import React from "react";
import styles from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";

import image from '../../../assets/images/about/image.webp';

const Banner = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.abs}>
          <img src={image} alt="" className={styles.image} />
        </div>
        <div className={styles.item}>
          <h1 className={`${styles.title} font-40-35 title`}>{t('about.first.title0')}</h1>
          <div className={`${styles.title} font-40-35 title`}>{t('about.first.title1')}</div>
          <div className={`${styles.text} font-16-14`}>{t('about.first.text')}</div>
          <div className={`${styles.center} mt-btn`}><Button>{t('btn.trading')}</Button></div>
        </div>
      </div>
    </section>
  );
};

export default Banner;