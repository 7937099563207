import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import ImageGrid from '../components/GlodalComponents/ImageGrid/ImageGrid';
import Steps from '../components/GlodalComponents/Steps/Steps';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Banner from '../components/TradingPage/Banner/Banner';
import Assets from '../components/TradingPage/Assets/Assets';
import Rewards from '../components/TradingPage/Rewards/Rewards';
import Explore from '../components/TradingPage/Explore/Explore';

function TradingPage() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Oakwell Finance · Trading Tools</title>
        <meta name="title" content="Empowering Traders with Cutting-Edge Tools" />
        <meta property="og:title" content="Empowering Traders with Cutting-Edge Tools" />
        <meta property="twitter:title" content="Empowering Traders with Cutting-Edge Tools" />

        <meta name="description" content="Maximize your trading potential with Oakwell Finance's comprehensive suite of tools. Our platform offers advanced charting and risk management features, giving you the resources needed to make informed decisions and stay ahead in the markets." />
        <meta property="og:description" content="Maximize your trading potential with Oakwell Finance's comprehensive suite of tools. Our platform offers advanced charting and risk management features, giving you the resources needed to make informed decisions and stay ahead in the markets." />
        <meta property="twitter:description" content="Maximize your trading potential with Oakwell Finance's comprehensive suite of tools. Our platform offers advanced charting and risk management features, giving you the resources needed to make informed decisions and stay ahead in the markets." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='trading-page ovf-hidden'>
        <Header />
        <Banner />
        <ImageGrid />
        <Assets />
        <Rewards />
        <Explore />
        <Steps />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default TradingPage;
