import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Steps from '../components/GlodalComponents/Steps/Steps';
import Banner from '../components/AboutPage/Banner/Banner';
import Key from '../components/AboutPage/Key/Key';
import Tabs from '../components/AboutPage/Tabs/Tabs';

function AboutPage() {
  return (
    <HelmetProvider>
      <Helmet>
      <title>Oakwell Finance · About us</title>
        <meta name="title" content="Discover the Vision and Expertise of Oakwell Finance" />
        <meta property="og:title" content="Discover the Vision and Expertise of Oakwell Finance" />
        <meta property="twitter:title" content="Discover the Vision and Expertise of Oakwell Finance" />

        <meta name="description" content="Learn more about Oakwell Finance, where our vision is to empower traders of all backgrounds to thrive in the financial markets. Join us and see how our expertise and commitment can help you achieve your trading goals." />
        <meta property="og:description" content="Learn more about Oakwell Finance, where our vision is to empower traders of all backgrounds to thrive in the financial markets. Join us and see how our expertise and commitment can help you achieve your trading goals." />
        <meta property="twitter:description" content="Learn more about Oakwell Finance, where our vision is to empower traders of all backgrounds to thrive in the financial markets. Join us and see how our expertise and commitment can help you achieve your trading goals." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='about-page ovf-hidden'>
        <Header />
        <Banner />
        <Key />
        <Tabs />
        <Steps />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default AboutPage;
