import React from "react";
import styles from './FAQ.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Accordion from '../../GlodalComponents/Accordion/Accordion';

const FAQ = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={`${styles.title} font-40-20 title`}><Trans>{t('account.third.title')}</Trans></div>
        <Accordion title='account.third.list' />
      </div>
    </section>
  );
};

export default FAQ;