import React from "react";
import styles from './Fees.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import Accordion from '../../GlodalComponents/Accordion/Accordion';

const Fees = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.item}>
          <div className={`${styles.title} font-60-35 title`}><Trans>{t('main.four.title')}</Trans></div>
          <div className={`${styles.text} font-16-14`}>{t('main.four.text0')}</div>
          <div className={`${styles.text} font-16-14`}>{t('main.four.text1')}</div>
          <div className="mt-btn desk_only"><Button>{t('btn.learn')}</Button></div>
        </div>
        <div className={styles.item}>
          <Accordion title='main.four.list' />
        </div>
      </div>
    </section>
  );
};

export default Fees;