import React from "react";
import styles from './Rewards.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import Accordion from '../../GlodalComponents/Accordion/Accordion';

const Rewards = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.item}>
          <Accordion bordered title='account.sec.list' />
        </div>
        <div className={styles.item}>
          <div className={`${styles.title} font-60-35 title`}><Trans>{t('account.sec.title0')}</Trans></div>
          <div className={`${styles.title} font-60-35 title`}><Trans>{t('account.sec.title1')}</Trans></div>
          <div className={`${styles.text} font-16-14`}>{t('account.sec.text0')}</div>
          <div className={`${styles.text} font-16-14`}>{t('account.sec.text1')}</div>
          <div className="mt-btn desk_only"><Button>{t('btn.learn')}</Button></div>
        </div>
      </div>
    </section>
  );
};

export default Rewards;