import React from "react";
import styles from './BannerGrid.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import { Fade } from "react-reveal";

const BannerGrid = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <Fade bottom>
          <div className={`${styles.item} ${styles.itemFirst}`}>
            <h1 className={`${styles.title} font-60-40`}>{t('fees.first.title')}</h1>
            <div className={`${styles.text} font-16-14`}>{t('fees.first.text')}</div>
            <div className={`${styles.center} mt-btn`}><Button>{t('btn.trading')}</Button></div>
          </div>
        </Fade>
        <Fade bottom>
          <div className={`${styles.item} ${styles.itemSec}`}>
            <div className={`${styles.subtitle} font-25-20`}>{t('fees.first.title1')}</div>
            <div className={`${styles.text} font-16-14`}>{t('fees.first.text1')}</div>
          </div>
        </Fade>
        <Fade bottom>
          <div className={`${styles.item} ${styles.itemThird}`}>
            <div className={`${styles.subtitle} font-25-20`}>{t('fees.first.title2')}</div>
            <div className={`${styles.text} font-16-14`}>{t('fees.first.text2')}</div>
          </div>
        </Fade>
        <Fade bottom>
          <div className={`${styles.item} ${styles.itemFour}`}>
            <div className={`${styles.subtitle} font-25-20`}>{t('fees.first.title3')}</div>
            <div className={`${styles.text} font-16-14`}>{t('fees.first.text3')}</div>
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default BannerGrid;