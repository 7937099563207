import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Steps from '../components/GlodalComponents/Steps/Steps';
import Tabs from '../components/AccountPage/Tabs/Tabs';
import Rewards from '../components/AccountPage/Rewards/Rewards';
import FAQ from '../components/AccountPage/FAQ/FAQ';

// import Banner from '../components/AboutPage/Banner/Banner';

function AccountPage() {
  return (
    <HelmetProvider>
      <Helmet>
      <title>Oakwell Finance · Account types</title>
        <meta name="title" content="Choose Your Path to Trading Success with Oakwell Finance" />
        <meta property="og:title" content="Choose Your Path to Trading Success with Oakwell Finance" />
        <meta property="twitter:title" content="Choose Your Path to Trading Success with Oakwell Finance" />

        <meta name="description" content="Select the account type that suits your trading journey on Oakwell Finance. From Starter to VIP and Islamic accounts, each offers unique features and benefits. Find the perfect fit for your trading style and aspirations." />
        <meta property="og:description" content="Select the account type that suits your trading journey on Oakwell Finance. From Starter to VIP and Islamic accounts, each offers unique features and benefits. Find the perfect fit for your trading style and aspirations." />
        <meta property="twitter:description" content="Select the account type that suits your trading journey on Oakwell Finance. From Starter to VIP and Islamic accounts, each offers unique features and benefits. Find the perfect fit for your trading style and aspirations." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='account-page ovf-hidden'>
        <Header />
        <Tabs />
        <Rewards />
        <FAQ />
        {/* <Banner /> */}
        <Steps />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default AccountPage;
