import React from "react";
import styles from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import { Reveal } from "react-reveal";

import image from '../../../assets/images/trading/image.webp';

const Banner = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <Reveal effect="zoomOut">
        <div className={`${styles.container} container`}>
          <h5 className={`${styles.title} font-60-40 title`}>{t('trading.first.title0')}</h5>
          <h1 className={`${styles.subtitle} font-60-40`}>{t('trading.first.title1')}</h1>
          <div className={`${styles.text} font-16-14`}>{t('trading.first.text0')}</div>
          <div className={`${styles.text} font-16-14`}>{t('trading.first.text1')}</div>
          <div className={`${styles.center} mt-btn`}><Button>{t('btn.trading')}</Button></div>
          <div className={styles.abs}>
            <img src={image} alt="" className={styles.image} />
          </div>
        </div>
      </Reveal>
    </section>
  );
};

export default Banner;