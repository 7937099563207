import React from 'react';
import { useTranslation } from "react-i18next";
import logo from '../../../assets/images/footer-logo.svg';
import styles from './Footer.module.scss';

const Footer = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;
  
  return (
    <footer className={styles.footer}>
      <div className='container'>
        <div className={styles.footerContent}>
          <div className={styles.leftSide}>
            <div className={styles.logoWrap}>
              <img src={logo} alt="Logo" className={styles.headerLogo} />
            </div>
            <div className={styles.footerNav}>
              <div className={styles.itemBlock} >
                <div className={`${styles.itemBlock} ${styles.navTitle}`}>
                  New Court Abbey RoadNorth, Shepley, Huddersfield, HD8 8BJ
                </div>
                <div className={styles.itemBlock}>
                  <a href="tel:+441235643224" className={styles.navTitle}>+ 441235643224</a>
                </div>
                <div className={styles.itemBlock}>
                  <a href={`mailto:support@${hostname}`} className={styles.navTitle}>
                    support@{hostname}
                  </a>
                </div>
              </div>
              <div className={styles.itemBlock}>
                <a  
                  href="/docs/CONFLICT_OF_INTERESTS.pdf"
                  target="_blank"
                  className={styles.navTitle}
                >
                  {t('footer.docs.conflict')}
                </a>

                <a
                  href="/docs/PRIVACY_POLICY.pdf"
                  target="_blank"
                  className={styles.navTitle}
                >
                  {t('footer.docs.privacy')}
                </a>
                
                <a  
                  href="/docs/Client_agreement.pdf"
                  target="_blank"
                  className={styles.navTitle}
                >
                  {t('footer.docs.client_ag')}
                </a>

                <a  
                  href="/docs/CySec.pdf"
                  target="_blank"
                  className={styles.navTitle}
                >
                  {t('footer.docs.CySEC')}
                </a>
                {/* <a
                  href="/docs/FCA.pdf"
                  target="_blank"
                  className={styles.navTitle}
                >
                  {t('footer.docs.FCA')}
                </a> */}
                <a  
                  href="/docs/Central_Bank.pdf"
                  target="_blank"
                  className={styles.navTitle}
                >
                  {t('footer.docs.central_bank')}
                </a>

                <a
                  href="https://find-and-update.company-information.service.gov.uk/company/09243199"
                  target="_blank"
                  className={styles.navTitle}
                >
                  {t('footer.docs.regulation')}
                </a>
                
                <a  
                  href="https://search.gleif.org/#/record/213800F2KOZ59UMP8P38"
                  target="_blank"
                  className={styles.navTitle}
                >
                  {t('footer.docs.LEI')}
                </a>
              </div>
            </div>
          </div>
          <div className={styles.rightSide}>
            <div className={styles.navTitle}>{t('footer.disclaimer.title')}</div>
            <div className={styles.text}>
              <p>{t('footer.disclaimer.text_01')}</p>
              <p>{t('footer.disclaimer.text_02')}</p>
            </div>
            {/* <div className={styles.copyright}>{t('footer.rights')}</div> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;