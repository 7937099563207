import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useParams } from "react-router-dom";
import Header from '../components/GlodalComponents/Header/Header';
import Tabs from '../components/FeesPage/Tabs/Tabs';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Steps from '../components/GlodalComponents/Steps/Steps';
import Banner from '../components/FeesPage/Banner/Banner';
import TradingFees from '../components/FeesPage/TradingFees/TradingFees';
import BannerGrid from '../components/FeesPage/BannerGrid/BannerGrid';

function FeesPage() {
  const { lang } = useParams();

  return (
    <HelmetProvider>
      <Helmet>
        <title>Oakwell Finance · Fees</title>
        <meta name="title" content="Transparent Fee Structure for Informed Trading" />
        <meta property="og:title" content="Transparent Fee Structure for Informed Trading" />
        <meta property="twitter:title" content="Transparent Fee Structure for Informed Trading" />

        <meta name="description" content="Gain clarity on trading costs with Oakwell Finance's transparent fee structure. We believe in fair and competitive pricing, ensuring you have a clear understanding of the fees associated with your trades. Explore our fee details for a seamless trading experience." />
        <meta property="og:description" content="Gain clarity on trading costs with Oakwell Finance's transparent fee structure. We believe in fair and competitive pricing, ensuring you have a clear understanding of the fees associated with your trades. Explore our fee details for a seamless trading experience." />
        <meta property="twitter:description" content="Gain clarity on trading costs with Oakwell Finance's transparent fee structure. We believe in fair and competitive pricing, ensuring you have a clear understanding of the fees associated with your trades. Explore our fee details for a seamless trading experience." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className={`world-page ovf-hidden ${lang}`}>
        <Header />
        <BannerGrid />
        <Banner />
        <TradingFees />
        <Tabs />
        <Steps />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default FeesPage;
