import React from "react";
import styles from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";

import image from '../../../assets/images/fees/image.webp';

const Banner = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.item}>
          <div className={`${styles.text} font-16-14`}>{t('fees.sec.text0')}</div>
          <div className={`${styles.text} font-16-14`}>{t('fees.sec.text1')}</div>
          <div className={`${styles.center} mt-btn`}><Button>{t('btn.trading')}</Button></div>
        </div>
        <div className={styles.abs}>
          <img src={image} alt="" className={styles.image} />
        </div>
      </div>
    </section>
  );
};

export default Banner;