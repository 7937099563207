import HomePage from '../pages/HomePage';
import AboutPage from '../pages/AboutPage';
import AccountPage from '../pages/AccountPage';
import FeesPage from '../pages/FeesPage';
import SupportPage from '../pages/SupportPage';
import TradingPage from '../pages/TradingPage';

export const routes = [
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/trading', element: TradingPage },
    { path: '/:lang/fees', element: FeesPage},
    { path: '/:lang/account', element: AccountPage},
    { path: '/:lang/support', element: SupportPage },
    { path: '/:lang/about', element: AboutPage },
];


export const headerRoutes = [
    { path: 'trading', text: 'header.trading' },
    { path: 'fees', text: 'header.fees' },
    { path: 'account', text: 'header.account' },
    { path: 'support', text: 'header.support' },
    { path: 'about', text: 'header.about' },
];