import React from "react";
import styles from './BannerGrid.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

import image from '../../../assets/images/support/image2.webp';

const BannerGrid = () => {
  const { t } = useTranslation();
  const list = t('support.sec.list', { returnObjects: true })

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.grid}>
          {list.map((item, index) => (
            <Fade bottom>
              <div className={styles.gridItem}>
                  <div className={`${styles.title} font-20-18`}><Trans>{t(`${item.title}`)}</Trans></div>
                  <div className={`${styles.text} font-16-14`}><Trans>{t(`${item.text}`)}</Trans></div>
              </div>
            </Fade>
          ))}
        </div>
        <Fade bottom>
          <div className={styles.item}>
            <img src={image} alt="" className={styles.image} />
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default BannerGrid;