import React from "react";
import styles from './ImageGrid.module.scss';
import { useTranslation } from "react-i18next";

import image1 from '../../../assets/images/homepage/grid1.webp';
import image2 from '../../../assets/images/homepage/grid2.webp';
import image3 from '../../../assets/images/homepage/grid3.webp';
import image4 from '../../../assets/images/homepage/grid4.svg';
import image5 from '../../../assets/images/homepage/grid5.webp';
import image6 from '../../../assets/images/homepage/grid6.webp';

const ImageGrid = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={`${styles.item} ${styles.itemLeft}`}>
          <div className={`${styles.innerItem}`}>
            <img src={image1} alt="" className={styles.abs} />
            <div className={`font-30-25`}>{t('main.third.list1')}</div>
          </div>
          <div className={`${styles.innerItem} ${styles.innerItemSec}`}>
            <img src={image2} alt="" className={styles.abs} />
            <div className={`font-30-25`}>{t('main.third.list2')}</div>
          </div>
        </div>
        <div className={styles.itemCenter}>
          <img src={image3} alt="" className={styles.abs} />
          <div className={`font-30-25`}>{t('main.third.list3')}</div>
        </div>
        <div className={styles.itemRight}>
        <div className={`${styles.innerItem} ${styles.innerItemFirst}`}>
          <img src={image4} alt="" className={styles.abs} />
            <div className={`font-30-25`}>{t('main.third.list4')}</div>
          </div>
          <div className={`${styles.innerItem} ${styles.innerItemSec}`}>
            <img src={image5} alt="" className={styles.abs} />
            <div className={`font-30-25`}>{t('main.third.list5')}</div>
          </div>
          <div className={`${styles.innerItem} ${styles.innerItemLast}`}>
            <img src={image6} alt="" className={styles.abs} />
            <div className={`font-30-25`}>{t('main.third.list6')}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImageGrid;