import React from "react";
import styles from './FAQ.module.scss';
import { useTranslation } from "react-i18next";
import Accordion from "../../GlodalComponents/Accordion/Accordion";

const FAQ = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={`${styles.title} font-40-30 title`}>{t('support.third.title')}</div>
        <Accordion title='support.third.list' short />
      </div>
    </section>
  );
};

export default FAQ;