import React from "react";
import { useParams } from "react-router-dom";
import styles from './Key.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import Accordion from '../../GlodalComponents/Accordion/Accordion';

const Key = () => {
  const { t } = useTranslation();
  const { lang } = useParams();

  return (
    <section className={`${styles.wrap} ${styles[lang]}`}>
      <div className={`${styles.container} container`}>
        <div className={styles.item}>
          <div className={`${styles.title} font-60-35 title`}><Trans>{t('about.sec.title')}</Trans></div>
          <div className={`${styles.text} font-16-14`}>{t('about.sec.text')}</div>
          <div className="mt-btn desk_only"><Button>{t('btn.singup')}</Button></div>
        </div>
        <div className={styles.item}>
          <Accordion title='about.sec.list' />
        </div>
      </div>
    </section>
  );
};

export default Key;