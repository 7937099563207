import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from '../components/GlodalComponents/Header/Header';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Steps from '../components/GlodalComponents/Steps/Steps';
import Banner from '../components/SupportPage/Banner/Banner';
import BannerGrid from '../components/SupportPage/BannerGrid/BannerGrid';
import FAQ from '../components/SupportPage/FAQ/FAQ';

function SupportPage() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Oakwell Finance · Support FAQ</title>
        <meta name="title" content="Oakwell Finance Customer Support and In-depth FAQ are Ready to Assist You" />
        <meta property="og:title" content="Oakwell Finance Customer Support and In-depth FAQ are Ready to Assist You" />
        <meta property="twitter:title" content="Oakwell Finance Customer Support and In-depth FAQ are Ready to Assist You" />

        <meta name="description" content="Take advantage of dedicated customer support and an extensive FAQ section to guide you through your trading journey with Oakwell Finance. Whether you need immediate assistance or detailed answers to your questions, we're here to help." />
        <meta property="og:description" content="Take advantage of dedicated customer support and an extensive FAQ section to guide you through your trading journey with Oakwell Finance. Whether you need immediate assistance or detailed answers to your questions, we're here to help." />
        <meta property="twitter:description" content="Take advantage of dedicated customer support and an extensive FAQ section to guide you through your trading journey with Oakwell Finance. Whether you need immediate assistance or detailed answers to your questions, we're here to help." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className='support-page ovf-hidden'>
        <Header />
        <Banner />
        <BannerGrid />
        <FAQ />
        <Steps />
        <Footer />
      </div>
    </HelmetProvider>
  )
  
}

export default SupportPage;
