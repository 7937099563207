import React from "react";
import styles from './WhyChoose.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Accordion from '../../GlodalComponents/Accordion/Accordion';

import image from '../../../assets/images/homepage/image1.webp';

const WhyChoose = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={styles.item}>
          <div className={`${styles.title} font-60-35 title`}><Trans>{t('main.sec.title')}</Trans></div>
          <div className={`${styles.text} font-16-14`}>{t('main.sec.text')}</div>
        </div>
        <div className={styles.abs}>
          <img src={image} alt="" className={styles.image} />
        </div>
        <Accordion title='main.sec.list' />
      </div>
    </section>
  );
};

export default WhyChoose;