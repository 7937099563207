import React from "react";
import styles from './SupportFAQ.module.scss';
import { useTranslation } from "react-i18next";

import image from '../../../assets/images/homepage/image6.webp';

const SupportFAQ = () => {
  const { t } = useTranslation();
  const list = t('main.six.list', { returnObjects: true });

  return (
    <section className={styles.wrap}>
      <div className={styles.row}>
        <div className={styles.rowLeft}>
          <div className={`${styles.title} font-60-35`}>{t('main.six.title')}</div>
          <div className={`${styles.text} font-16-14`}>{t('main.six.text')}</div>
        </div>
        <div className={styles.rowRight}>
          <img src={image} alt="" className={styles.image} />
        </div>
      </div>
      <div className={styles.list}>
        {list.map((item, index) => (
          <div className={styles.listItem} key={index}>
            <div className={`${styles.listItemTitle} font-20-18`}>{item.title}</div>
            <div className={`${styles.listItemText} font-16-14`}>{item.text}</div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default SupportFAQ;