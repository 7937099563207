import React from "react";
import styles from './Explore.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";

import icon from '../../../assets/images/check.svg';
import image2 from '../../../assets/images/trading/image1.webp';
import image1 from '../../../assets/images/trading/image2.webp';

const Explore = () => {
  const { t } = useTranslation();
  const list0 = t('trading.four.list0', { returnObjects: true });
  const list1 = t('trading.four.list1', { returnObjects: true });

  return (
    <section className={styles.wrap}>
      <div className={`${styles.container} container`}>
        <div className={`${styles.item} ${styles.itemFirst}`}>
          <div className={`${styles.title} font-60-30 title`}><Trans>{t('trading.four.title0')}</Trans></div>
          <div className={`${styles.title} font-60-30 title`}><Trans>{t('trading.four.title1')}</Trans></div>
          <div className={`${styles.text} font-16-14`}><Trans>{t('trading.four.text0')}</Trans></div>
          <div className={styles.list}>
            {list0.map((item, index) => (
              <div className={styles.listItem} key={index}>
                <img src={icon} alt="" className={styles.listItemIcon} />
                <div className={`${styles.listItemTitle} font-17-14`}>
                  <Trans>{t(`${item.title}`)}</Trans>
                </div>
              </div>
            ))}
          </div>
          <div className={styles.image}><img src={image1} alt="" /></div>
        </div>
        <div className={`${styles.item} ${styles.itemSec}`}>
          <div className={styles.image}><img src={image2} alt="" /></div>
          <div className={styles.list}>
            {list1.map((item, index) => (
              <div className={styles.listItem} key={index}>
                <img src={icon} alt="" className={styles.listItemIcon} />
                <div className={`${styles.listItemTitle} font-17-14`}>
                  <Trans>{t(`${item.title}`)}</Trans>
                </div>
              </div>
            ))}
          </div>
          <div className={`${styles.text} font-16-14`}><Trans>{t('trading.four.text1')}</Trans></div>
          <div className="mt-btn"><Button>{t('btn.trading')}</Button></div>
        </div>
      </div>
    </section>
  );
};

export default Explore;