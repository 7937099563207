import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useParams } from "react-router-dom";
import Header from '../components/GlodalComponents/Header/Header';
import Steps from '../components/GlodalComponents/Steps/Steps';
import ImageGrid from '../components/GlodalComponents/ImageGrid/ImageGrid';
import Footer from '../components/GlodalComponents/Footer/Footer';
import Banner from '../components/HomePage/Banner/Banner';
import WhyChoose from '../components/HomePage/WhyChoose/WhyChoose';
import Trading from '../components/HomePage/Trading/Trading';
import Fees from '../components/HomePage/Fees/Fees';
import AccTypes from '../components/HomePage/AccTypes/AccTypes';
import SupportFAQ from '../components/HomePage/SupportFAQ/SupportFAQ';

function HomePage() {
  const { lang } = useParams();

  return (
    <HelmetProvider>
      <Helmet>
        <title>Oakwell Finance</title>
        <meta name="title" content="Discover the Opportunities of Successful Trading with Oakwell Finance" />
        <meta property="og:title" content="Discover the Opportunities of Successful Trading with Oakwell Finance" />
        <meta property="twitter:title" content="Discover the Opportunities of Successful Trading with Oakwell Finance" />

        <meta name="description" content="Explore a world of financial instruments and opportunities on the main page of Oakwell Finance. Join us in redefining online trading with a user-friendly platform, expert insights." />
        <meta property="og:description" content="Explore a world of financial instruments and opportunities on the main page of Oakwell Finance. Join us in redefining online trading with a user-friendly platform, expert insights." />
        <meta property="twitter:description" content="Explore a world of financial instruments and opportunities on the main page of Oakwell Finance. Join us in redefining online trading with a user-friendly platform, expert insights." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className={`ovf-hidden main-page ${lang}`}>
        <Header />
        <Banner />
        <WhyChoose />
        <Trading />
        <ImageGrid />
        <Fees />
        <AccTypes />
        <SupportFAQ />
        <Steps />
        <Footer />
      </div>
    </HelmetProvider>
  )
}

export default HomePage;
