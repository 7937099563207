import React from "react";
import { useParams } from "react-router-dom";
import styles from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlodalComponents/Button/Button";
import { Reveal } from "react-reveal";

import image from '../../../assets/images/homepage/image0.webp';

const Banner = () => {
  const { t } = useTranslation();
  const { lang } = useParams();

  return (
    <section className={`${styles.wrap} ${styles[lang]}`}>
      <Reveal effect="zoomOut">
        <div className={`${styles.container} container`}>
          <h5 className={`${styles.title} font-105-70 title smallerTitle`}>{t('main.first.title0')}</h5>
          <h1 className={`${styles.subtitle} font-65-40 title`}>{t('main.first.title1')}</h1>
          <div className={`${styles.text} font-16-14`}>{t('main.first.text')}</div>
          <div className={`${styles.center} mt-btn`}><Button>{t('btn.trading')}</Button></div>
          <div className={styles.abs}>
            <img src={image} alt="" className={styles.image} />
          </div>
        </div>
      </Reveal>
    </section>
  );
};

export default Banner;