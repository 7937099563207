import React from "react";
import { useParams } from "react-router-dom";
import styles from './Trading.module.scss';
import { Trans, useTranslation } from "react-i18next";

const Trading = () => {
  const { t } = useTranslation();
  const { lang } = useParams();

  return (
    <section className={`${styles.wrap} ${styles[lang]}`}>
      <div className={`${styles.container} container`}>
        <div className={styles.item}>
          <div className={`${styles.title} font-60-35 title`}><Trans>{t('main.third.title')}</Trans></div>
          <div className={`${styles.text} font-16-14`}>{t('main.third.text0')}</div>
          <div className={`${styles.text} font-16-14`}>{t('main.third.text1')}</div>
        </div>
        <div className={`${styles.text} ${styles.last} font-16-14`}>{t('main.third.text2')}</div>
      </div>
    </section>
  );
};

export default Trading;